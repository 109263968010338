<template>
    <div class="col-span-full">
        <div
            class="flex flex-wrap items-center justify-center gap-2">
            <template v-if="modelValue && modelValue.length">
                <div
                    v-for="document in modelValue"
                    :key="'document-' + document.id"
                    class="w-32">
                    <div
                        class="w-32 h-32 rounded relative"
                        :class="{
                            'bg-primary':!disabled && !document.mime_type.startsWith('image/'),
                            'bg-neutral-500':disabled && !document.mime_type.startsWith('image/'),
                        }">
                        <img
                            v-if="document.mime_type.startsWith('image/')"
                            :src="document.url"
                            :alt="document.file_name"
                            class="w-full h-full object-cover">

                        <Icon
                            v-else
                            :name="fileIcons.getIcon(document)"
                            class="w-full h-full text-white dark:text-gray-800" />

                        <div
                            v-if="!disabled"
                            class="absolute top-0 right-0 pr-2 pt-2">
                            <button
                                type="button"
                                title="Entfernen"
                                class="rounded bg-neutral-600/50 hover:bg-danger-500 text-white focus:outline-none focus:ring-2 focus:ring-danger-500 focus:ring-offset-2"
                                @click="removeFile(document)">
                                <span class="sr-only">Close</span>
                                <Icon
                                    name="heroicons:trash"
                                    class="h-6 w-6 p-1"
                                    aria-hidden="true" />
                            </button>
                        </div>
                    </div>
                    <div class="w-100 truncate">
                        <a
                            href="#"
                            @click.prevent="downloadFile(document)">
                            <small>{{ document.file_name }}</small>
                        </a>
                    </div>
                </div>
            </template>

            <template v-if="files && files.length">
                <div
                    v-for="(_, index) in files"
                    :key="'uploading-file-' + index"
                    class="animate-pulse">
                    <div class="w-32 h-4 bg-neutral-300 rounded-full" />
                </div>
            </template>
        </div>
        <div
            v-if="showDropzone"
            id="drop_zone"
            class="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 dark:border-gray-200/25 px-6 py-10 drag"
            :class="{'bg-gray-200': dragging}">
            <div
                class="text-center"
                @drop.prevent="dropFiles"
                @dragenter.prevent="dragging = true"
                @dragover.prevent="dragging = true"
                @dragleave.prevent="dragging = false">
                <Icon
                    :name="uploadIcon"
                    class="h-8 w-8" />
                <div class="mt-4 flex text-sm leading-6 text-gray-600 dark:text-gray-300">
                    <label
                        for="file-upload"
                        class="relative cursor-pointer rounded-md bg-white dark:bg-gray-700 font-semibold focus-within:outline-none focus-within:ring-2 focus-within:ring-primary-600 focus-within:ring-offset-2 dark:ring-offset-gray-700"
                        :class="{
                            'text-neutral-500 dark:text-neutral-500': disabled,
                            'text-primary-600 dark:text-primary hover:text-primary-500': !disabled,
                        }">
                        <span>{{ $t('files.upload') }}</span>
                        <input
                            id="file-upload"
                            name="file-upload"
                            type="file"
                            :disabled="disabled"
                            :accept="accept"
                            class="sr-only"
                            :multiple="multiple"
                            @change="inputFiles">
                    </label>
                    <p class="pl-1">
                        {{ $t('files.orDragAndDrop') }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {fileIcons} from "@/utils/fileIcons";

const props = defineProps({
    modelValue: {
        type: Array,
        required: true
    },
    visibility: {
        type: String,
        default: 'local'
    },
    accept: {
        type: String,
        default: ''
    },
    multiple: {
        type: Boolean,
        default: false
    },
    disabled: {
        type: Boolean,
        default: false
    },
    folder: {
        type: String,
        default: ''
    },
    uploadIcon: {
        type: String,
        default: 'heroicons:camera'
    }
})

const emits = defineEmits(['update:modelValue'])

const {$apiRoute} = useNuxtApp()

const files = ref([])
const dragging = ref(false)

const localDocuments = ref([])

const showDropzone = computed(() => {
    return props.multiple || !props.modelValue?.length
})

watchEffect(() => {
    localDocuments.value = [...(props.modelValue ?? [])]
})

function downloadFile(document) {
    if (document.url)
        window.open(document.url, '_blank')
    else
        window.open($apiRoute('spa.documents.show', {document: document.id}), "_blank");
}

function removeFile(document) {
    if(props.disabled) return
    localDocuments.value = localDocuments.value.filter(doc => doc.id !== document.id)
    emits('update:modelValue', [...localDocuments.value])
}

function dropFiles(event) {
    if(props.disabled) return
    event.preventDefault();
    dragging.value = false
    if (!event.dataTransfer?.files?.length) return
    const actualFiles = props.multiple ? event.dataTransfer.files : [event.dataTransfer.files[0]]
    files.value = [...files.value, ...actualFiles]
    uploadFiles()
}

function inputFiles(event) {
    if(props.disabled) return
    event.preventDefault();
    dragging.value = false
    if (!event.target?.files?.length) return
    const actualFiles = props.multiple ? event.target.files : [event.target.files[0]]
    files.value = [...files.value, ...actualFiles]
    uploadFiles()
}

function uploadFiles() {
    if(props.disabled) return

    const promises = files.value.map(file => {
        return new Promise((resolve, reject) => {
            const formData = new FormData()
            formData.append('payload', JSON.stringify({
                visibility: props.visibility,
                folder: props.folder
            }))
            formData.append('file', file)
            $lara.post($apiRoute('spa.documents.store'), {
                body: formData,
                formDataRequest: true
            }).then(response => {
                localDocuments.value.push(response.data)
                files.value.splice(files.value.indexOf(file), 1)
                resolve(response.data)
            }).catch(error => {
                // remove error file
                files.value.splice(files.value.indexOf(file), 1)
                reject(error)
            })
        })
    })

    Promise.all(promises).then(() => {
        emits('update:modelValue', [...localDocuments.value])
    }).catch(error => {
        console.log(error)
    })
}
</script>

<style scoped>

</style>