type FileIcons = {
    name: { [key: string]: string };
    getIcon(document: any): string;
}

type WordMimeTypes = string[]

type ExcelMimeTypes = string[]

const wordMimeTypes: WordMimeTypes = [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
]

const excelMimeTypes: ExcelMimeTypes = [
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
]

export const fileIcons: FileIcons = {
    name: {
        pdf: 'bx:bxs-file-pdf',
        word: 'ant-design:file-word-filled',
        excel: 'mdi:file-excel',
        undefined: 'bx:bxs-file-blank'
    },

    getIcon(document) {
        let type

        if (document.mime_type === 'application/pdf') {
            type = 'pdf'
        } else if (wordMimeTypes.find((wordMimeType) => wordMimeType === document.mime_type)) {
            type = 'word'
        } else if (excelMimeTypes.find((excelMimeType) => excelMimeType === document.mime_type)) {
            type = 'excel'
        } else {
            type = 'undefined'
        }
        return fileIcons.name[type]
    }
};